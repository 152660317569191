export default {
  formValidation: {
    emptyField: 'Поле, обязательное для заполнения',
    requiredField: 'Поле, обязательное для заполнения',
    passwordToShort: 'Поле должно состоять из 8 символов',
    emailNotValid: 'Aдрес email неправильный',
    emailReserved: 'Данный email уже существует',
    rateEmpty: 'Все поля должны быть заполнены',
    serverUnknownError: 'Ошибка связи с сервером',
    invalidCharacters: 'Pole zawiera niepoprawne znaki',
    phoneNotValid: 'Numer telefonu jest niepoprawny',
    checkBoxBelow: 'Zaznacz proszę pole poniżej, aby wysłać wiadomość',
  },
  password: {
    show: 'Показать',
    hide: 'скрывать',
  },
}
