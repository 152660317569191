const openMenu = (): void => {
  const $menu = document.querySelector<HTMLElement>('.header__menu-wrapper')
  const $toggler = document.querySelector<HTMLButtonElement>('button.header__mobile-button')
  const $background = document.querySelector<HTMLElement>('.header__menu-background')

  if ($menu && $toggler) {
    $toggler.addEventListener('click', (): void => {
      $menu.classList.toggle('open')
    })

    $background.addEventListener('click', (): void => {
      $menu.classList.remove('open')
    })
  }
}

const expandNavigation = (): void => {
  const $nextDepthElements = document.querySelectorAll<HTMLLIElement>('.header__menu-wrapper .menu-item-has-children')
  const $currentAncestors = document.querySelectorAll<HTMLLIElement>('.header__menu-wrapper .current-menu-ancestor')

  $currentAncestors.forEach((el: HTMLLIElement): void => {
    el.classList.add('open')
  })

  $nextDepthElements.forEach(($nextDepthElement: HTMLLIElement): void => {
    const $toggler = $nextDepthElement.querySelector<HTMLAnchorElement>('a')

    $toggler.addEventListener('click', (e): void => {
      window.innerWidth < 992 ? e.preventDefault() : ''
      $nextDepthElement.classList.toggle('open')
    })
  })
}

const openThirdDepth = (): void => {
  const $secondDepthMenus = document.querySelectorAll<HTMLUListElement>('.main-desktop-menu__sub-menu-2')

  $secondDepthMenus.forEach(($submenu: HTMLUListElement): void => {
    const toggler = $submenu.previousElementSibling

    toggler.addEventListener('click', (e): void => {
      e.preventDefault()
      $submenu.classList.toggle('open')
    })
  })
}

export default function initDesktopMenu(): void {
  openMenu()
  expandNavigation()
  openThirdDepth()
}
