const openMenu = (): void => {
  const $menu = document.querySelector<HTMLElement>('#main-mobile-menu-container')
  const $toggler = $menu?.querySelector<HTMLButtonElement>('.main-mobile-menu__btn')
    ? $menu?.querySelector<HTMLButtonElement>('.main-mobile-menu__btn')
    : $menu?.querySelector<HTMLAnchorElement>('.main-mobile-menu > .current-menu-item > a')

  if ($menu && $toggler) {
    $toggler.addEventListener('click', (): void => {
      $menu.classList.toggle('open')
    })
  }
}

export default function initMobileMenu(): void {
  openMenu()
}
