export default {
  formValidation: {
    emptyField: "Обов'язкове поле",
    requiredField: "Обов'язкове поле",
    passwordToShort: 'Пароль повинен містити не менше 8 символів',
    emailNotValid: 'поле має містити правильний адрес електронної пошти',
    emailReserved: 'Даний email вже зайнятий',
    rateEmpty: 'Усі поля мають бути заповнені',
    serverUnknownError: "Помилка зв'язку з сервером.",
    invalidCharacters: 'Pole zawiera niepoprawne znaki',
    phoneNotValid: 'Numer telefonu jest niepoprawny',
    checkBoxBelow: 'Zaznacz proszę pole poniżej, aby wysłać wiadomość',
  },
  password: {
    show: 'шоу',
    hide: 'приховати',
  },
  howItWorks: {
    showButton: 'показати більше',
    hideButton: 'показувати менше',
  },
}
