import initDesktopMenu from 'components/desktop-menu'
import initMobileMenu from 'components/mobile-menu'
import initLangSwitcher from 'components/lang-switcher'
import { inputPaginationMechanism } from 'components/blog-pagination'
import initPromoPopup from 'components/promo-popup'
import { spawnTrackingEventListners } from 'components/tracking-ga-events'
import cookieConsent from 'services/cookies-consent'

cookieConsent.init()
initDesktopMenu()
initMobileMenu()
initLangSwitcher()
inputPaginationMechanism()
initPromoPopup()
spawnTrackingEventListners()
