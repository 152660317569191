interface CustomWindow extends Window {
  dataLayer: any[]
}
declare let window: CustomWindow

interface BasicDataLayerEvent {
  button_type?: string
  button_text?: string
  button_url?: string
  button_src?: string
  form_name?: string
  form_url?: string
  calculator_type?: string
  calculator_value?: string
  calculator_value_from?: string
  click_text?: string
  popup_view?: string
  cookies_category?: string
  cookies_button_placement?: string
}

export function pushDataLayer(eventName: string, eventParams: BasicDataLayerEvent): void {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: eventName,
    ...eventParams,
  })
}
