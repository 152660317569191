export const inputPaginationMechanism = (): void => {
  const $paginationInputElement = document.querySelector<HTMLInputElement>('#blogPageNumber')
  if ($paginationInputElement) {
    const $actualPageNumber = parseInt(
      document.querySelector<HTMLDivElement>('[data-page-number]').getAttribute('data-page-number'),
      10,
    )
    const $maxInputValue = $paginationInputElement.getAttribute('max')
    const $minInputValue = $paginationInputElement.getAttribute('min')
    const docUrl = document.URL
    const urlSegments = docUrl.split('/')
    const pageName =
      urlSegments[4] === 'page' || !urlSegments[4]
        ? urlSegments[3].replace(/\/$/, '')
        : `${urlSegments[3]}/${urlSegments[4]}`

    $paginationInputElement.addEventListener('focus', () => {
      $paginationInputElement.value = ''
    })

    $paginationInputElement.addEventListener('focusout', () => {
      $paginationInputElement.value === '' || $paginationInputElement.value === '0'
        ? ($paginationInputElement.value = String($actualPageNumber))
        : ''
    })

    $paginationInputElement.addEventListener('keypress', (e) => {
      ;['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
      const inputNumber = $paginationInputElement.value
      inputNumber.length > 2 ? e.preventDefault() : ''
      const userInput = parseInt($paginationInputElement.value, 10)

      if (e.key === 'Enter') {
        if (userInput < parseInt($maxInputValue, 10) + 1 && userInput > parseInt($minInputValue, 10) - 1) {
          const page = $actualPageNumber < 1 ? '/page' : `${pageName}`
          const clearUrl = document.URL.substring(0, document.URL.indexOf(page))
          const urlToRedirect =
            userInput <= 1 ? `${clearUrl}${pageName}/` : `${clearUrl}${pageName}/page/${inputNumber}/`
          window.location.href = urlToRedirect
        } else $paginationInputElement.value = String($actualPageNumber)
      }
    })
  }
}
