import cookieService from 'services/cookie-service'

export enum SupportedLanguages {
  pl = 'pl',
  en = 'en',
  ru = 'ru',
  uk = 'uk',
}

export const getLangFromCookie = (): string => {
  const cookieLang = cookieService.getItem('language')
  if (SupportedLanguages[cookieLang]) {
    return cookieLang
  }
  return null
}

export const getLangFromUrl = (): string => {
  const url = window.location.href
  let lang = 'pl'
  if (url.includes('.pl/en/') || url.endsWith('.pl/en')) lang = 'en'
  else if (url.includes('.pl/ru/') || url.endsWith('.pl/ru')) lang = 'ru'
  else if (url.includes('.pl/uk/') || url.endsWith('.pl/uk')) lang = 'uk'
  return lang
}

export const getExplodedURL = (): string[] => {
  const explodedURL = window.location.pathname.split('/')
  // Remove empty elements
  // Most probably first and last element as URL have first character as /
  // and trailing slash at the end.
  return explodedURL.filter((el) => {
    return el.length !== 0
  })
}

const currentLanguage =
  typeof window !== 'undefined'
    ? process?.env?.LANGUAGE || getLangFromCookie() || getLangFromUrl() || SupportedLanguages.pl
    : ''

export const getCurrentLanguage = (): string => currentLanguage
