export default {
  formValidation: {
    emptyField: 'Pole nie może być puste',
    requiredField: 'Pole jest wymagane',
    passwordToShort: 'Hasło musi mieć 8 znaków',
    emailNotValid: 'Pole musi zawierać poprawny email',
    emailReserved: 'Adres e-mail jest już zajęty',
    rateEmpty: 'Wszystkie pola muszą być uzupełnione',
    serverUnknownError: 'Błąd komunikacji z serwerem',
    invalidCharacters: 'Pole zawiera niepoprawne znaki',
    phoneNotValid: 'Numer telefonu jest niepoprawny',
    checkBoxBelow: 'Zaznacz proszę pole poniżej, aby wysłać wiadomość',
  },
  password: {
    show: 'pokaż',
    hide: 'ukryj',
  },
  howItWorks: {
    showButton: 'Zobacz więcej',
    hideButton: 'Zobacz mniej',
  },
}
