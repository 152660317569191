export function getCookieDomain(domain: string): string {
  return (domain || location.hostname).replace(/^(https?:\/\/)?(www\.)?/, '.')
}

export function doesUrlContainParam(key: string, urlParamsObject?: object): boolean {
  urlParamsObject = urlParamsObject || getCookieByParamUrl()
  return !!urlParamsObject[key]
}

export function getCookieByParamUrl(): object {
  const urlParams = new URLSearchParams(window.location.search)
  return Array.from(urlParams.keys()).length > 0 ? Object.fromEntries(urlParams.entries()) : {}
}

const cookieService = {
  setItem: (key: string, value: string, sameSite = 'None', days = 365, domain = ''): void => {
    const currentDate = new Date()
    currentDate.setTime(+currentDate + days * 86400000)

    document.cookie = `${key}=${value}; path=/; domain=${getCookieDomain(
      domain,
    )}; expires=${currentDate.toUTCString()}; SameSite=${sameSite}; Secure`
  },
  getItem: (name: string): string | null => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    return parts.length === 2 ? parts.pop().split(';').shift() : null
  },
  removeItem(key: string): void {
    document.cookie = `${key}=; path=/`
  },
  removeCookie(key: string, domain?: string | null): void {
    const $zeroTime = new Date(0)
    document.cookie = `${key}=; expires=${$zeroTime.toUTCString()}${domain ? `; domain=${domain}` : ''}; path=/`
  },
  setCookieByParamUrl(paramKey: string, cookieKey: string): void {
    const urlParamsObject = getCookieByParamUrl()
    if (doesUrlContainParam(paramKey, urlParamsObject))
      cookieService.setItem(cookieKey, urlParamsObject[paramKey], 'None', 90)
  },
  setTrasactCookies(paramKey: string[], cookieKey: string): void {
    const urlParamsObject = getCookieByParamUrl()
    if (doesUrlContainParam('ref')) {
      const cookieContent = paramKey.reduce((acc, key) => {
        return doesUrlContainParam(key) && urlParamsObject[key] !== '' ? `${acc}&${key}=${urlParamsObject[key]}` : acc
      }, `${urlParamsObject['ref']}`)
      const finalCookieContent = cookieContent.startsWith('&') ? cookieContent.slice(1) : cookieContent
      cookieService.setItem(cookieKey, finalCookieContent, 'None', 90)
    }
  },
}

export default cookieService
