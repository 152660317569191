import { getCurrentLanguage } from '../utils/current-language'
import pl from './languages/pl'
import en from './languages/en'
import uk from './languages/uk'
import ru from './languages/ru'

// for landing pages use lang param and use function from src/landings/__assets__/scripts/utils/current-language.ts
export const t9n = (key: string, lang: string = getCurrentLanguage()): string => {
  const isNested = key.includes('.')
  const arr = isNested ? key.split('.') : [key]
  switch (lang) {
    case 'en':
      return isNested ? en[arr[0]][arr[1]] : en[key]
    case 'uk':
      return isNested ? uk[arr[0]][arr[1]] : uk[key]
    case 'ru':
      return isNested ? ru[arr[0]][arr[1]] : ru[key]
    default:
      return isNested ? pl[arr[0]][arr[1]] : pl[key]
  }
}
