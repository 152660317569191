import { PromoPopupPayload } from 'models/endpoints-payload'
import { promiseWrapper } from 'utils/promise'

declare const USER_BASE_URL_WT: string

export const signUpForPromoCode = (payload: PromoPopupPayload): Promise<void> => {
  return promiseWrapper<void, PromoPopupPayload>(
    `${USER_BASE_URL_WT}/api/public/cmsPopupPromoCodeEmail`,
    'POST',
    payload,
  )
}
