import cookieService from 'services/cookie-service'
import { pushDataLayer } from 'utils/data-layer-push'
import { signUpForPromoCode } from '../services/promo-popup-service'
import { getCurrentLanguage } from '../utils/current-language'
import { t9n } from 't9n/t9n'

const EXIT_POPUP_DELAY = 10000

const getEmail = () => {
  return document.querySelector<HTMLInputElement>('[data-ex-popup-email-input]').value.trim()
}

const isValidEmail = () => {
  const rawEmail = getEmail()
  const regex =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i
  return regex.test(rawEmail)
}

const getMarketingConsentValue = () => {
  const checkboxElement = document.querySelector<HTMLInputElement>('[data-ex-popup-consent]')
  return checkboxElement.checked
}

const isValidMarketingConsent = () => {
  return getMarketingConsentValue()
}

const hideAllErrors = () => {
  for (const element of document.querySelectorAll<HTMLElement>('[data-ex-popup-error]')) {
    element.hidden = true
  }
}

const wasNotLoggedRecently = () => {
  return document.cookie.match(/^(.*)?\s*user_id\s*=\s*[^]+(.*)?$/) === null
}

const wasNotDismissedByUser = () => {
  return document.cookie.match(/^(.*)?\s*promo_popup\s*=\s*[^]+(.*)?$/) === null
}

const wasNotDisplayedToUser = () => {
  return sessionStorage.getItem('promoPopup__showed') === null
}

const showError = (errorType: string, errorPlace: string) => {
  const errorElement = document.querySelector<HTMLElement>(`[data-ex-popup-error="${errorPlace}"]`)

  if (errorElement) {
    errorElement.hidden = false
    errorElement.textContent = t9n(`formValidation.${errorType}`)
  }
}

const validateInput = () => {
  if (!isValidEmail()) {
    showError('emailNotValid', 'email')
    return
  }

  if (!isValidMarketingConsent()) {
    showError('requiredField', 'rodo')
    return
  }

  const payload = {
    email: getEmail(),
    language: getCurrentLanguage(),
  }

  signUpForPromoCode(payload)
    .then(() => {
      showThankYouPopup()
    })
    .catch((errResponse) => {
      if (errResponse === 422) {
        showError('emailReserved', 'email')
      } else {
        showError('serverUnknownError', 'email')
      }
    })
}

const showThankYouPopup = () => {
  document.querySelector<HTMLElement>('[data-ex-popup-form]').hidden = true
  document.querySelector<HTMLElement>('[data-ex-popup-success]').hidden = false

  pushDataLayer('form_submitted', { form_name: 'Zapis na kod rabatowy' })
}

const closeExitPopup = () => {
  document.querySelector<HTMLElement>('[data-ex-popup]').hidden = true

  pushDataLayer('click_button', {
    button_type: 'Button',
    button_text: 'Zamknięcie popupu kodu rabatowego',
  })

  cookieService.setItem('promo_popup', 'true', 'None', 30)
}

const bindActionsForForm = () => {
  const $exitPopupInput = document.querySelector('[data-ex-popup-email-input]')
  if ($exitPopupInput) {
    $exitPopupInput.addEventListener('input', () => {
      hideAllErrors()

      const $element = document.querySelector('[data-ex-popup-consent-holder]')
      if ($element && isValidEmail()) {
        $element.classList.add('is-open')
      } else {
        $element.classList.remove('is-open')
      }
    })
  }

  const $exitSubmit = document.querySelector('[data-ex-popup-submit]')
  if ($exitSubmit) {
    $exitSubmit.addEventListener('click', () => {
      hideAllErrors()
      validateInput()
    })
  }

  const $closeElements = document.querySelectorAll('[data-ex-popup-close]')
  $closeElements.forEach(($singleElement) => {
    $singleElement.addEventListener('click', closeExitPopup)
  })
}

const showPopup = () => {
  if (wasNotLoggedRecently() && wasNotDisplayedToUser() && wasNotDismissedByUser()) {
    cookieService.setItem('promoPopup__showed', 'true')

    document.querySelector<HTMLElement>('[data-ex-popup]').hidden = false

    pushDataLayer('popup_view', { form_name: 'Zapis na kod rabatowy' })
  }
}

const initPromoPopup = () => {
  if (document.querySelector('[data-ex-popup]')) {
    setTimeout(() => {
      showPopup()
    }, EXIT_POPUP_DELAY)
  }

  bindActionsForForm()
}

export default initPromoPopup
