import cookieService from 'services/cookie-service'
import { getExplodedURL, SupportedLanguages, getLangFromCookie, getLangFromUrl } from 'utils/current-language'

const redirectToLanguage = (lang: string): void => {
  lang.toLowerCase() === 'pl' || lang.toLowerCase() === 'null' ? (lang = '') : (lang = `${lang}/`)
  window.location.href = `//${window.location.hostname}/${lang}`
}

/**
 *  Function check if current URL is homepage
 *  @return true || false
 */
const isHomepagePl = (): boolean => {
  const explodedURL = getExplodedURL()
  if (explodedURL.length === 0) return true
  if (SupportedLanguages[explodedURL[0].toLowerCase()] >= 0 && explodedURL.length === 1) {
    return true
  }
  return false
}

const handleDropdownExpand = () => {
  const $dropdownElements = document.querySelectorAll<HTMLDivElement>('[data-lang-dropdown]')
  $dropdownElements.forEach(($dropdownElement) => {
    $dropdownElement.hidden = !$dropdownElement.hidden
  })
}

const handleLanguageChange = (event: Event) => {
  const $selectedElement = event.currentTarget as HTMLLIElement
  const selectedLang = $selectedElement.getAttribute('data-select-lang')
  cookieService.setItem('language', selectedLang, 'Lax')
  redirectToLanguage(selectedLang)
}

export default function initLangSwitcher(): void {
  const $langSwitcherElements = document.querySelectorAll<HTMLDivElement>('[data-lang-switcher]')
  $langSwitcherElements.forEach(($singleLangSwitcher) => {
    $singleLangSwitcher.addEventListener('click', handleDropdownExpand)
  })

  const $langOptions = document.querySelectorAll<HTMLLIElement>('[data-select-lang]')
  $langOptions.forEach(($singleLangElement) => {
    $singleLangElement.addEventListener('click', handleLanguageChange)
  })

  window.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
      let cookieLang = String(getLangFromCookie()).toLowerCase()
      const urlLang = String(getLangFromUrl()).toLowerCase()

      if (cookieLang === 'null') {
        cookieService.setItem('language', urlLang, 'Lax')
        cookieLang = urlLang
      }

      if (cookieLang !== urlLang && isHomepagePl()) {
        setTimeout(() => {
          redirectToLanguage(cookieLang)
        }, 50)
      }
    }, 0)
  })
}
