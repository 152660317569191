export default {
  formValidation: {
    emptyField: 'The field cannot be empty',
    requiredField: 'The field is required',
    passwordToShort: 'The password must be 8 characters long',
    emailNotValid: 'The field must contain a valid email address',
    emailReserved: 'The email address is already taken',
    rateEmpty: 'All fields must be completed',
    serverUnknownError: 'Server communication error',
    invalidCharacters: 'Field contains invalid characters',
    phoneNotValid: 'The phone number is incorrect',
    checkBoxBelow: 'Please tick the box below to send a message',
  },
  password: {
    show: 'show',
    hide: 'hide',
  },
  howItWorks: {
    showButton: 'Show more',
    hideButton: 'Show less',
  },
}
